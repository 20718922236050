import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import textStyles from "../styles/text.module.scss"
import quickStartStyles from '../styles/quick-start.module.scss';
import classNames from 'classnames';
import laptop2PhonesSvg from '../images/laptop-2-phones.svg';
import videoCallSvg from '../images/video-call.svg';
import { Link } from "gatsby"
import BackToIndex from "../components/back-to-index";

const QuickStartPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Quick Start Guide" />
      <div className={classNames(textStyles.title, textStyles.big)}>
        Quick Start <span className={textStyles.highlight}>Guide</span>
      </div>

      <img src={laptop2PhonesSvg} className={ classNames(quickStartStyles.fullWidthImage) } />
      <div className={textStyles.title}>
        Start your <span className={textStyles.highlight}>game</span>
      </div>
      <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.closeAtTop) }>
        <ol>
          <li>Choose a game from the <Link to={'/#games'} target={'_blank'}>homepage</Link> and complete the purchase.</li>
          <li>Run the game in <a href='https://www.google.co.uk/chrome/' target={'_blank'} rel={'noopener noreferrer'}>Google Chrome</a> on the fastest / newest PC or laptop in your house. You'll find a link to the game in your email.</li>
        </ol>
      </div>

      <img src={videoCallSvg} className={ classNames(quickStartStyles.fullWidthImage) } />
      <div className={textStyles.title}>
        Play on a <span className={textStyles.highlight}>video call</span>
      </div>
      <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.closeAtTop) }>
        <ol>
          <li>Start your own video call (or join one!) on the same PC running the game.</li>
          <li>Share your screen on the call and hit the game’s fullscreen button.</li>
          <li>Everyone on the call, including you, can get their phones out and follow the instructions on your shared screen. You need at least 2 players to start the race.
</li>
          <li>RACE!</li>
        </ol>
      </div>

      <div className={textStyles.title}>
        What do I need <span className={textStyles.highlight}>to run a game?</span>
      </div>
      <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.closeAtTop) }>
        <ul>
          <li>The fastest / newest PC or laptop in your house with the <a href='https://www.google.co.uk/chrome/' target={'_blank'} rel={'noopener noreferrer'}>Google Chrome</a> browser installed</li>
          <li>An internet connection. The faster the better. Speed always helps in a race!</li>
          <li>A phone to join in the game.</li>
        </ul>
      </div>

      <div className={textStyles.title}>
        What do <span className={textStyles.highlight}>players</span> need?
      </div>
      <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.closeAtTop) }>
        <ul>
          <li>Every player needs to be able to see the video call – make it on something with a big screen, like a laptop.</li>
          <li>If there are several people in one room, you can all watch the same screen.</li>
          <li>Players will need another device (like a phone or tablet) to join and play the game.</li>
        </ul>
      </div>

      <div className={textStyles.title}>
        Top <span className={textStyles.highlight}>tips</span>
      </div>
      <div className={ classNames(textStyles.leftAlign, textStyles.body, textStyles.closeAtTop) }>
        <ol>
          <li>If the whole family are playing, why not plug a laptop into your TV so you can all see it?</li>
          <li>Make sure you get the best performance by quitting all the other apps running on your PC and closing all other tabs in Chrome.</li>
          <li>Check the <Link to={'/faq'}>FAQs</Link> for more.</li>
        </ol>
      </div>

      <BackToIndex buttonText={'← Home'} />

    </Layout>
  )
}

export default QuickStartPage